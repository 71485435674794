#Main_tabel {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: clip;

}

#Main_ul {
  height: auto;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
     width: 97.6%;
  background: #878496;
}

#Main_ul li {
  width: 14%;
  font-weight:600;
}

#Main_tabel_content {
  width: 100%;
  height: 80vh;
  
  overflow-y: scroll;

}
#Main_tabel1{
  width: 100%;
  padding: 10px;
}
#Main_tr{
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  color: #5231e4;
  background: #f9f9f9;
  margin-top: 10px;
  height: 1vh;
 

}
#Main_tr td{
  width: 14%;
  overflow: hidden;
  max-width: 14%;
  
}
#Main_img{
  padding: 15px;
}
#Main_img img{
  width: 10%;
}
#dani{
  padding: 8px;
  color: black;
  border-radius: 5px;
  background: #EEEAFC;
  border-style: none;
  cursor: pointer;
}
#butt{
  display: flex;
  justify-content: end;
}
#Main_input input{
  border: 1px solid #9581EE; 
  border-radius: 5px;
  width: 12%;
  margin-left: 10px;
  padding: 5px;
}
@media (max-width: 768px) {
  #Main_tabel {
    width: 120%;
   
  }
  #Main_ul li {
    width: auto;
  }
  #Main_tr td{
    width: 14%;
    overflow: hidden;
    max-width: 14%;
  }
}
@media (max-width: 425px) {
  #Main_tabel {
    width: 250%;
  }

}