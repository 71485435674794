 .Signin{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.SigninBox1 h3{
    display: flex;
    justify-content: center;
}
.SigninInput{
    display: flex;
    flex-direction: column;
}
.SigninInput label{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.SigninInput input{
    border: 1px solid #9581ee;
    border-radius: 4px;
}
.SigninInput button{
    align-items: center;
    background: #5231e4;
    border-radius: 10px;
    border-style: none;
    color: #fff;
    display: flex;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    margin: 25px 0 0;
    cursor: pointer;
    } 

     @media (max-width: 2560px) {
        .SigninBox{
            width: 40%;
            height: 50vh;
            background: #F9F9F9;
            border-radius: 10px;
            padding: 30px;
        }
        .SigninInput label{
          
            font-size: 36px;
        }
        .SigninInput input{
          
            font-size: 36px;
        }
        .SigninBox1 h3{
            font-size: 42px;
        }
        .SigninInput input{
            padding: 20px;
        }
        .SigninInput button{
            padding: 20px;
            font-size: 36px;
        }
        .paseror span{
        font-size: 24px;
        color: red;
        }
        .Signineror{
            color: red;
            font-size: 24px;
        }
    }
    @media (max-width: 1920px) {
        .SigninBox{
            width: 40%;
            height: 50vh;
            background: #F9F9F9;
            border-radius: 10px;
            padding: 30px;
        }
        .SigninInput label{
          
            font-size: 24px;
        }
        .SigninInput input{
          
            font-size: 24px;
        }
        .SigninBox1 h3{
            font-size: 32px;
        }
        .SigninInput input{
            padding: 8px;
        }
        .SigninInput button{
            padding: 15px;
            font-size: 24px;
        }
        .paseror span{
            font-size: 20px;
            }
            .Signineror{
                font-size: 14px;
            }
    }
    @media (max-width: 1440px) {
        .SigninBox{
            width: 40%;
            height: 40vh;
            background: #F9F9F9;
            border-radius: 10px;
            padding: 30px;
        }
        .SigninInput label{
          
            font-size: 18px;
        }
        .SigninInput input{
          
            font-size: 18px;
        }
        .SigninBox1 h3{
            font-size: 24px;
        }
        .SigninInput input{
            padding: 8px;
        }
        .SigninInput button{
            padding: 8px;
            font-size: 18px;
        }
        .paseror span{
            font-size: 14px;
            }
            .Signineror{
                font-size: 14px;
            }
    }
    @media (max-width: 1024px) {
        .SigninBox{
            width: 40%;
            height: 40vh;
            background: #F9F9F9;
            border-radius: 10px;
            padding: 30px;
        }
        .SigninInput label{
          
            font-size: 14px;
        }
        .SigninInput input{
          
            font-size: 14px;
        }
        .SigninBox1 h3{
            font-size: 20px;
        }
        .SigninInput button{
            font-size: 14px;
        }
        .paseror span{
            font-size: 12px;
            }
            .Signineror{
                color: red;
                font-size: 12px;
            }
    }
    @media (max-width: 768px) {
        .SigninBox{
            width: 50%;
            height: 40vh;
            background: #F9F9F9;
            border-radius: 10px;
            padding: 30px;
        }
    }
    @media (max-width: 425px) {
        .SigninBox{
            width: 70%;
            height: 40vh;
            background: #F9F9F9;
            border-radius: 10px;
            padding: 30px;
        }
    } 