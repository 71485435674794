#Main_Data{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
#Main_Data_ul{
    height: auto;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
       width: 97%;
    background: #878496; 
}
#Main_Data_ul li{
    width: 11%;
  font-weight:600 ;
  font-size: 12px;
}
#Main_Data_tr{
    display: flex;
    justify-content: space-between;
    padding: 25px;
    align-items: center;
    color: #5231e4;
    background: #f9f9f9;
    margin-top: 10px;
    height: 1vh;
}
#Main_Data_tr td{
    width: 11%;
    overflow: hidden;
    max-width: 11%;

}
#Size{
    font-size: 10px;
}
.databutton{
     width: 100%;
    color: white;
    border-radius: 5px; 
    border-style: none;
    padding: 5px;
    cursor: pointer;
}
#aktiv{
    background:  #93D900;
}
#blok{
    background: red;
    margin-top: 5px;
}
@media (max-width: 768px) {
    #Main_Data{
        width: 150%;
    }
  }
  @media (max-width: 425px) {
    #Main_Data{
        width: 275%;
    }
  
  }
